import {
  Address,
  GoodDistributionInput,
  GoodInput,
  NewShipmentInput,
  Scalars,
  ShipmentChargeInput,
  ShipmentDocumentInput,
  ShipmentLocationInput,
} from "../../graphql/generated";

export interface FieldErrors {
  [key: string]: string;
}

export enum PredefinedRecurrence {
  ONE_TIME = "ONE_TIME",
  EVERY_DAY = "EVERY_DAY",
  EVERY_WEEK_DAY = "EVERY_WEEK_DAY",
  EVERY_WEEK_ON_DAY = "EVERY_WEEK_ON_DAY",
  EVERY_MONTH_ON_DATE = "EVERY_MONTH_ON_DATE",
  EVERY_MONTH_ON_FIRST_DAY = "EVERY_MONTH_ON_FIRST_DAY",
  EVERY_MONTH_ON_LAST_DAY = "EVERY_MONTH_ON_LAST_DAY",
  EVERY_MONTH_ON_NTH_DAY = "EVERY_MONTH_ON_NTH_DAY",
  EVERY_YEAR_ON_DATE = "EVERY_YEAR_ON_DATE",
  CUSTOM = "CUSTOM",
}

export interface ShipmentLocationInputData extends ShipmentLocationInput {
  _id: string;
  isDefaultFor?: string | null;
}

export interface NewShipmentInputData extends NewShipmentInput {
  id?: Scalars["String"];
  shipmentNumber?: Scalars["String"];
  shipmentLocations: Array<ShipmentLocationInputData>;
  documents: ShipmentDocumentInputData[];
  charges: ShipmentChargeInputData[];
  date: string | null;
  predefinedRecurrence: PredefinedRecurrence | null;
}

export interface ShipperStateType {
  shippers: Array<ShipmentLocationInputData>;
}

export interface ShippedGoodsPayload {
  _id: string;
  goods: Array<GoodInput>;
}

export interface ReceiverFormData {
  _id: string;
  name: string;
  address: Address;
  locationType: string;
  receiver: string;
  receivedGoods: Array<GoodInput>;
  date: string;
  deliveryTimeStart: Scalars["DateTime"];
  deliveryTimeEnd: Scalars["DateTime"];
  errors?: Array<FieldErrors>;
}

export interface ReceiverStateType {
  receivers: Array<ReceiverFormData>;
}

export interface ReceivedGoodsPayload {
  _id: string;
  receivedGoods: Array<GoodDistributionInput>;
}

export interface GoodDistributionUpdateInput {
  _id: string;
  goodId: string;
  quantity: number;
}

export interface FormError {
  formId: string;
  error: FieldErrors;
}

export type ShipmentDocumentInputData = ShipmentDocumentInput & {
  file?: File;
  _id: string;
};

export type ShipmentChargeInputData = ShipmentChargeInput;

export type BestBuyOption = {
  shipperId: string;
  supplierId: string;
  goodProfileId: string;
  unitPrice: number;
};
