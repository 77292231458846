import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { endOfDay, startOfDay } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ShipmentsList, {
  ShipmentListItem,
} from "../../../shipment/ShipmentsList/ShipmentsList";

export type AutoPlanModalProps = {
  isOpen: boolean;
  shipments: ShipmentListItem[];
  onClose: () => void;
  onPlan: (startDate: Date, endDate: Date, shipmentIds: string[]) => void;
};

const AutoPlanModal = ({
  isOpen,
  shipments,
  onClose,
  onPlan,
}: AutoPlanModalProps) => {
  const { t } = useTranslation("planning");

  const [startDate, setStartDate] = useState<Date>(startOfDay(new Date()));
  const [endDate, setEndDate] = useState<Date>(endOfDay(new Date()));
  const [filteredShipmentIds, setFilteredShipmentIds] = useState<string[]>([]);

  const shipmentsInDateRange = shipments.filter(
    (shipment) =>
      new Date(shipment.route?.firstPickupTime) >= startDate &&
      new Date(shipment.route?.lastDropoffTime) <= endDate
  );

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>{t("autoPlanModal.title")}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            py: 2,
          }}
        >
          <DateRangePicker
            value={[startDate, endDate]}
            onChange={(newValue) => {
              if (newValue[0]) {
                setStartDate(startOfDay(newValue[0]));
              }
              if (newValue[1]) {
                setEndDate(endOfDay(newValue[1]));
              }
            }}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </>
            )}
          />

          <ShipmentsList
            tableId="autoPlanModal-shipments"
            query={() => ({
              data: {
                shipments: {
                  data: shipmentsInDateRange,
                  count: shipmentsInDateRange.length,
                },
              },
              isLoading: false,
            })}
            selectable={false}
            disablePagination
            disableStatusTab
            excludePastShipments
            readonly
            onFilteredShipmentsChange={(filteredShipments) => {
              setFilteredShipmentIds(filteredShipments.map((s) => s._id));
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("autoPlanModal.cancel")}</Button>
        <Button
          onClick={() => {
            onPlan(startDate, endDate, filteredShipmentIds);
            onClose();
          }}
        >
          {t("autoPlanModal.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AutoPlanModal;
