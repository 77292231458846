import {
  TripSortCriteria,
  GetTractorListQuery,
  AssetStatus,
  NewTractorInput,
  CustomFieldContext,
  TripAssetTypes,
} from "../../../graphql/generated";
import { TableField } from "../../common/LynksTable/LynksTable";
import SmartLynksTable, {
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import { EnumTableField } from "../../common/LynksTable/LynksTable";
import ExcelImporter from "../../common/ExcelImporter";
import tractorSchema from "../TractorForm/tractorSchema";
import tractorsMapping from "../../../utils/mapping/tractors";
import { Box, Button, Chip, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import MaintenanceWarningsContainer from "../../maintenance/MaintenanceWarnings";

type TractorListItem = GetTractorListQuery["tractors"]["data"][0];

export default function TractorsList({
  query,
  onBulkCreate,
}: {
  query: QueryFn<TractorListItem, "tractors", TripSortCriteria>;
  onBulkCreate: (data: NewTractorInput[]) => Promise<any>;
}) {
  const { t } = useTranslation(["assets", "common"]);

  const statusField: EnumTableField<TractorListItem, AssetStatus> = {
    value: (tractor) => tractor.status,
    label: t("assets:status", "Status"),
    valueLabel: (status) => t(`common:statusTypes.${status}`),
    type: "enum",
    colors: {
      ACTIVE: "primary",
      INACTIVE: "default",
    },
  };

  const fields: TableField<TractorListItem, AssetStatus>[] = [
    {
      value: "serialNumber",
      label: t("assets:code", "Asset code"),
      type: "string",
    },
    {
      value: "licenseNumber",
      label: t("assets:licenseNumber", "License number"),
      type: "string",
    },
    {
      value: (tractor) => tractor.domicile?.label,
      label: t("assets:address", "Address"),
      type: "string",
    },
    statusField,
    {
      value: "model",
      subtitle: (tractor) => tractor.make,
      label: t("assets:tractor.model", "Model"),
      type: "string",
    },
    {
      value: (tractor) => (
        <Stack direction="row" spacing={1}>
          {tractor.tags?.map((tag) => (
            <Chip label={tag} />
          ))}
        </Stack>
      ),
      sortBy: "tags",
      label: t("common:tags"),
      type: "string",
    },
    {
      value: (tractor) => (
        <MaintenanceWarningsContainer
          assetType={TripAssetTypes.Tractor}
          assetId={tractor._id}
        />
      ),
      label: t("maintenance"),
      type: "string",
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          height: "calc(100% - 74px)",
        }}
      >
        <SmartLynksTable
          query={query}
          dataKey="tractors"
          fields={fields}
          detailsUrlPrefix="/tractors/details"
          customFieldContext={[CustomFieldContext.Tractor]}
          selectable
          showGroups
          renderHeaderActions={() => (
            <Stack direction="row" spacing={2}>
              <ExcelImporter
                mapping={tractorsMapping}
                schema={tractorSchema}
                onBulkCreate={onBulkCreate}
              />
              <Button
                href="/tractors/new"
                variant="contained"
                color="secondary"
                size="large"
                id="new-tractor-button"
              >
                {t("assets:tractor.new", "New Tractor")}
              </Button>
            </Stack>
          )}
        />
      </Box>
    </div>
  );
}
