import { Link } from "@mui/material";
import {
  BusinessEntity,
  GetDriverDetailsQuery,
  GetTractorDetailsQuery,
  GetTrailerDetailsQuery,
  TripAssetTypes,
} from "../../../graphql/generated";
import driverLabel from "../../../utils/labels/driverLabel";
import tractorLabel from "../../../utils/labels/tractorLabel";
import trailerLabel from "../../../utils/labels/trailerLabel";
import carrierLabel from "../../../utils/labels/carrierLabel";

export type AssetLinkProps = {
  assetType: TripAssetTypes;
  asset:
    | GetTractorDetailsQuery["tractorById"]
    | GetTrailerDetailsQuery["trailerById"]
    | GetDriverDetailsQuery["driverById"]
    | BusinessEntity;
};

const AssetLink: React.FC<AssetLinkProps> = ({ assetType, asset }) => {
  return (
    <Link
      href={`/${assetType.toLowerCase()}s/details/${asset._id}`}
      target="_blank"
      component="a"
    >
      {assetType === TripAssetTypes.Driver
        ? driverLabel(asset as GetDriverDetailsQuery["driverById"])
        : assetType === TripAssetTypes.Tractor
        ? tractorLabel(asset as GetTractorDetailsQuery["tractorById"])
        : assetType === TripAssetTypes.Trailer
        ? trailerLabel(asset as GetTrailerDetailsQuery["trailerById"])
        : assetType === TripAssetTypes.Carrier
        ? carrierLabel(asset as BusinessEntity)
        : asset._id}
    </Link>
  );
};

export default AssetLink;
