import { Chip, Stack, Typography } from "@mui/material";
import {
  TripSortCriteria,
  GetDriverListQuery,
  AssetStatus,
  CustomFieldContext,
  TripAssetTypes,
} from "../../../graphql/generated";
import { TableField } from "../../common/LynksTable/LynksTable";
import SmartLynksTable, {
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import { EnumTableField } from "../../common/LynksTable/LynksTable";
import formatPhoneNumber from "../../../utils/labels/formatPhoneNumber";
import DriverLicenseExpiryWarning from "../DriverLicenseExpiryWarning/DriverLicenseExpiryWarning";
import { useTranslation } from "react-i18next";
import { capitalize } from "lodash";
import MaintenanceWarningsContainer from "../../maintenance/MaintenanceWarnings";
import QualificationWarningsContainer from "../../qualifications/QualificationWarnings";

type DriverListItem = GetDriverListQuery["drivers"]["data"][0];

export default function DriversList({
  query,
  renderHeaderActions,
}: {
  query: QueryFn<DriverListItem, "drivers", TripSortCriteria>;
  renderHeaderActions?: () => React.ReactNode;
}) {
  const { t } = useTranslation(["users", "assets", "common"]);

  const statusField: EnumTableField<DriverListItem, AssetStatus> = {
    value: (driver) => driver.status,
    label: t("driver.status.one", "Status"),
    valueLabel: (status) => t(`common:statusTypes.${status}`),
    type: "enum",
    colors: {
      ACTIVE: "primary",
      INACTIVE: "default",
    },
  };

  const fields: TableField<DriverListItem, AssetStatus>[] = [
    {
      value: (driver) => driver.license?.licenseNumber,
      label: capitalize(t("driver.license.one", "License")),
      type: "string",
    },
    {
      value: (driver) => {
        return (
          <Stack
            direction="row"
            spacing={1}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <DriverLicenseExpiryWarning driver={driver} />
            <Typography>
              {driver.firstname} {driver.lastname}
            </Typography>
          </Stack>
        );
      },
      label: t("driver.name", "Name"),
      type: "custom",
    },
    {
      value: (driver) => formatPhoneNumber(driver.phoneNumber),
      label: t("driver.contact", "Contact"),
      type: "string",
    },
    {
      value: (driver) => driver.domicileEntity?.name,
      label: t("driver.domicile", "Domicile"),
      type: "string",
    },
    statusField,
    {
      value: (driver) => (
        <Stack direction="row" spacing={1}>
          {driver.tags?.map((tag) => (
            <Chip label={tag} />
          ))}
        </Stack>
      ),
      sortBy: "tags",
      label: t("common:tags"),
      type: "string",
    },
    {
      value: (driver) => (
        <MaintenanceWarningsContainer
          assetType={TripAssetTypes.Driver}
          assetId={driver._id}
        />
      ),
      label: capitalize(t("assets:maintenance")),
      type: "string",
    },
    {
      value: (driver) => (
        <QualificationWarningsContainer
          assetType={TripAssetTypes.Driver}
          assetId={driver._id}
        />
      ),
      label: capitalize(t("assets:qualifications")),
      type: "string",
    },
  ];

  return (
    <SmartLynksTable
      query={query}
      dataKey="drivers"
      fields={fields}
      detailsUrlPrefix="/drivers/details"
      id="drivers-list"
      customFieldContext={[CustomFieldContext.Driver]}
      showGroups
      renderHeaderActions={renderHeaderActions}
    />
  );
}
