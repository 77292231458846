import { useDispatch } from "react-redux";
import {
  useBulkEditBusinessEntitiesMutation,
  useBulkEditGoodProfilesMutation,
  useBulkEditShipmentsMutation,
  useBulkEditTractorsMutation,
  useBulkEditTrailersMutation,
} from "../../../graphql/generated";
import { showToast } from "../../../redux/slices/alert/Alert.slice";
import BulkActionModal from "./BulkActionModal";
import { useConfirm } from "material-ui-confirm";
import { useTranslation } from "react-i18next";

export default function BulkActionModalContainer({
  entityIds,
  onSubmitComplete,
  dataKey,
  open,
  onClose,
}: {
  entityIds: string[];
  onSubmitComplete: () => void;
  dataKey: string;
  open: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation(["common"]);
  const bulkEditBusinessEntities = useBulkEditBusinessEntitiesMutation();
  const bulkEditTrailers = useBulkEditTrailersMutation();
  const bulkEditTractors = useBulkEditTractorsMutation();
  const bulkEditGoodProfiles = useBulkEditGoodProfilesMutation();
  const bulkEditOrders = useBulkEditShipmentsMutation();

  const dispatch = useDispatch();
  const confirm = useConfirm();

  return (
    <BulkActionModal
      open={open}
      onClose={onClose}
      onSubmit={async (bulkEditedData, runAfterSubmit) => {
        if (bulkEditedData.groups.length === 0) {
          await confirm({
            title: t("warning", "Warning"),
            description: t(
              "list.confirmDeleteGroups",
              "You are about to delete all groups from the selected items. Are you sure you want to continue ?"
            ),
            confirmationText: t("yesRemove", "Yes, Remove"),
            cancellationText: t("noCancel", "Cancel"),
            confirmationButtonProps: {
              color: "error",
              variant: "contained",
            },
          });
        } else {
          await confirm({
            title: t("confirmation", "Confirmation"),
            description: t(
              "list.confirmBulkUpdate",
              "You are about to update all selected items. Are you sure you want to continue ?"
            ),
            confirmationText: t("confirm", "Confirm"),
            cancellationText: t("cancel", "Cancel"),
            confirmationButtonProps: {
              color: "secondary",
              variant: "contained",
            },
          });
        }

        try {
          const mutation = {
            businessEntities: bulkEditBusinessEntities,
            trailers: bulkEditTrailers,
            tractors: bulkEditTractors,
            goodProfiles: bulkEditGoodProfiles,
            shipments: bulkEditOrders,
          }[dataKey];

          const editResponseKey = {
            businessEntities: "bulkEditBusinessEntities",
            trailers: "bulkEditTrailers",
            tractors: "bulkEditTractors",
            goodProfiles: "bulkEditGoodProfiles",
            shipments: "bulkEditShipments",
          }[dataKey];

          const editDataKey = {
            businessEntities: "editBusinessEntityData",
            trailers: "editTrailerData",
            tractors: "editTractorData",
            goodProfiles: "editGoodProfileData",
            shipments: "editshipmentsData",
          }[dataKey];

          if (!mutation) {
            throw new Error(`Invalid dataKey: ${dataKey}`);
          }

          // @ts-expect-error
          const response = await mutation.mutateAsync({
            ids: entityIds,
            // @ts-expect-error
            [editDataKey]: {
              groupIds: bulkEditedData.groups,
            },
          });
          // @ts-expect-error
          if (response[editResponseKey]) {
            // @ts-expect-error
            const count = response[editResponseKey].modifiedCount;
            dispatch(
              showToast({
                message: t("list.bulkUpdateSuccess", {
                  count,
                  defaultValue: `Successfully updated ${count} records`,
                }),
                type: "success",
              })
            );
          }
        } catch (error) {
          dispatch(
            showToast({
              message:
                t(
                  "list.bulkUpdateError",
                  "An error occurred while updating the selected items"
                ) +
                ": " +
                (error as Error | null)?.message,
              type: "error",
            })
          );
        }

        runAfterSubmit();
        onSubmitComplete();
      }}
      loading={bulkEditBusinessEntities.isLoading}
    />
  );
}
